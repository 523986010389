.box5 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    color: var(--textoEscuro)
}
@media only screen and (min-width: 768px){
    .box5 {
        height: 558px;
    }
    .box5-image{
        height: 241px;
    }
    .div-box5-esquerda {
        width: 464px;
        height: 450px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items:center;
    }
    .p-box5-1 {
        font-weight: bold;
        font-size: 40px;
        line-height: 150%;
    }
    .p-box5-2 {
        font-weight: normal;
        text-align: justify;
        font-size: 18px;
        line-height: 175%;
    }
}
@media only screen and (max-width: 768px){
    .box5 {
        flex-wrap: wrap;
        
        height: auto;
    }
    .div-box5-esquerda {
        width: 464px;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items:center;
        padding-bottom: 25px;
    }
    .box5-image{
        width: 90%;
        height: auto
    }
    .p-box5-1 {
        font-weight: bold;
        font-size: 28px;
        line-height: 150%;
    }
    .p-box5-2 {
        width: 90%;
        font-weight: normal;
        text-align: justify;
        font-size: 18px;
        line-height: 175%;
    }
}