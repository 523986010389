.sobre-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--fundoQuaseBranco);
}
.sobre-box2{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    color: var(--textoEscuro);

}
.sobre-box3{
    padding-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    background: var(--fundoBoxCreme);
}
.colabMFlex {
    padding-top: 30px;
    padding-bottom: 25px;
    display: flex ;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.colabCFlex {
    width: 1000px;
    padding-top: 30px;
    padding-bottom: 25px;
    display: flex ;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}
.divcolabM {
    margin-left: 20px;
    margin-bottom: 20px;

    width: 285px;
    background: var(--fundoQuaseBranco);
    
    box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px ;
    
}

.img-foto {
    height: 248px;
    width: 285px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.colabLinha1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 10%;
    padding-top: 10px;
    width: 80%;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    color: var(--textoMaisEscuro);
    
}
.colabLinha2 {font-family: Poppins;
    padding-left: 10%;
    padding-bottom: 10px;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: var(--azulFundo)}
/* .sobre-box1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--textoEscuro);
} */

@media only screen and (min-width: 768px){
    .link-topo-mobile {
        display: none;
    }
    .sobre-box1{
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 25px 0px;
    }
    .p-sobre-tit-1{
        font-family: Ebrima;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 150%;
        text-align: center;
        color: var(--textoMaisEscuro);
    }
    .img-sobre{
        width: 354px;
    }
    .sobre-box2{
        width: 740px ;
        height: 760px;
        margin-bottom: 25px;
    }
    .p-sobre-tit-2 {
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 175%;
        text-align: left;
    }
    .p-sobre-texto-1 {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 175%;
        text-align: left;
    }
    .p-sobre-texto-2 {
        display: list-item;          /* This has to be "list-item"                                               */
        list-style-type: disc;       /* See https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-type     */
        list-style-position: inside;
        margin-left: 8px;
        padding-left: 1.5em;
        text-indent:-1.5em;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 175%;
        text-align: left;
    }
}
@media only screen and (max-width: 768px){
    
    .sobre-box1{
        height: 300px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        padding: 25px 0px;
    }
    .p-sobre-tit-1{
        width: 90%;
        color: var(--textoMaisEscuro);
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 175%;
        text-align: center;
    }
    .img-sobre{
        width: 100%;
    }
    .sobre-box2{
        height: 1250px ;
        margin-bottom: 25px;
    }
    
    .sobre-box2 p{
        width: 90%;
        
    }
    .sobre-box3{
        padding-bottom: 30px;
        
    }
    .p-sobre-tit-2 {
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 175%;
        text-align: left;
        padding-left: 15px;
    }
    .p-sobre-texto-1 {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 175%;
        text-align: left;
        padding-left: 15px;
    }
    .p-sobre-texto-2 {
        display: list-item;          /* This has to be "list-item"                                               */
        list-style-type: disc;       /* See https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-type     */
        list-style-position: inside;
        margin-left: 8px;
        padding-left: 1.5em;
        text-indent:-1.5em;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 175%;
        text-align: left;
    }
    .link-topo-mobile {
        align-self: center;
        border: 2px solid var(--vermelhoLink);
        background: none;
        width: 174px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        align-items: center;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 175%;
        color: var(--vermelhoLink);
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
        border-radius: 50px;
        
    }
    .colabCFlex {
        width: 100%;
    }
    .divcolabM{
        margin: 0 0  20px 0;
    }
}
