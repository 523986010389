@media only screen and (min-width: 768px){
.barrainferior {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 284px;
    background: var(--azulFundo);
    padding: 25px 0px;
}


.div1-inf img {
    height: 48px;
}
.div2-inf {
    
    width: 550px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.cartinha {
    width: 18px;
    height: 18px;
}
.texto-inf {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 175%;
    color: var(--fundoQuaseBranco);  
}
.div3-inf {
    
    width: 400px ;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    
}
.but-redesocial {
    width: 48px;
    height: 48px;
}
.but-redesocial-f {
    width: 54px;
    height: 54px;
}
}
@media only screen and (max-width: 768px){
    .barrainferior {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 250px;
        background: var(--azulFundo);
        padding: 40px 20px;
    }
    
    
    .div1-inf img {
        height: 48px;
    }
    .div2-inf {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content:center;
        align-items: center;
    }
    .cartinha {
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }
    .texto-inf {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 25px;
        color: var(--fundoQuaseBranco);  
    }
    .div3-inf {
        
        width: 300px ;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        
    }
    .but-redesocial, .but-redesocial-f {
        width: 40px;
        height: 40px;
    }
}