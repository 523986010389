@media only screen and (min-width: 768px){
.barrasuperior {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: var(--azulFundo);
    height: 112px;
    width: 100%;
    left: 0px;
    top: 0px;
    border-radius: 0px;
    padding: 24px, 120px, 24px, 120px;
    /* justify: space-between */
}
.sup-esquerda{
    margin-left: 50px;
}
.logo-menu{
    height: 64px;
    
}
.barrasuperior button{
    background: none;
    border: 0;
}
.sup-direita{
    margin-right: 90px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
}
.texto-menu-At, .texto-menu-Inat{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 175%;
    margin-left:25px;
}
.texto-menu-At{
color: var(--menuAtTexto);
font-size: 20px;
}
.texto-menu-Inat{
    color: var(--menuInatTexto);
}
.sup-direita-mobile {
    display: none;
  }
}
/* responsivo */

@media only screen and (max-width: 768px) {
    .sup-direita {
        display: none;
      }
    .sup-direita-mobile{
    margin-right: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    color: white;
    }
    .barrasuperior {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: var(--azulFundo);
        height: 64px;
        width: 100%;
        left: 0px;
        top: 0px;
        border-radius: 0px;
        padding: 24px, 120px, 24px, 120px;
        /* justify: space-between */
    }
    .bot-hamburguer {
        border: 0;
    }
    .sup-esquerda{
        margin-left: 15px;
    }
    .logo-menu{
        height: 48px;
    }
    .barrasuperior button{
        background: none;
        border-right: 0;
        border-left: 0;
        border-top: 0;
    }
    .menuDrop{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        
        position: absolute;
        width: 244px;
        
        right: 0px;
        top: 64px;
        
        background: #304B72;        
    }
    .texto-menu-At, .texto-menu-Inat, .texto-menu-tit{
        text-align: left;
        padding: 15px 15px;
        font-family: Ebrima;
        font-style: normal;
        font-weight: normal;
        width: 100%;
        border-bottom: 1px solid gray;
    }
    .texto-menu-At{
        color: var(--menuAtTexto);
        font-size: 20px;
        }
        .texto-menu-Inat{
            color: var(--menuInatTexto);
            font-size: 16px;
            line-height: 24px;
        }
        .texto-menu-tit{
            color: var(--menuInatTexto);
            font-weight: bold;
            font-size: 24px;
            line-height: 31px;
        }
    
  }