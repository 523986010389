.sessaoPropAm p {
    font-family: Ebrima;
    font-style: normal;
    color: var(--textoEscuro);
    text-align: left
}
.sessaoPropAm{
    height: 500px;
    display:flex;
    justify-content: space-evenly;
    align-items: center;
    
}
.tituloSA {
    font-weight: bold;
    font-size: 32px;
    line-height: 150%;
}
.textoSA {
    font-size: 20px;
    line-height: 150%;
    
}
.imageSA{
    height: 300px;
}

.sesPA-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 500px;
    height: 380px;
}
.sesPA-info button{
    align-self: center;
}
@media only screen and (max-width: 768px){
    .sessaoPropAm{
        height: auto;
        flex-direction: column ;
    }
    .sesPA-info {
        
        width: 90%;
        margin-bottom: 25px;
        height: auto;
    }
    .imageSA{
        width: 90%;
        height: auto;
    }
}