.box6 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    color: var(--textoEscuro);
    font-family: Ebrima;
    font-style: normal;
}

@media only screen and (min-width: 768px){
    .box6 {
        height: 178px;
        background: var(--fundoBoxCreme);
    }
    .p-box-texto {
        font-weight: normal;
        text-align: justify;
        font-weight: 500;
        font-size: 24px;
        line-height: 175%;
    }
}
@media only screen and (max-width: 768px){
    .box6 {
        height: 140px;
        background: var(--fundoBoxCreme);
    }
    .p-box-texto {
        font-weight: normal;
        text-align: justify;
        font-size: 18px;
        line-height: 175%;
    }
}