.main-box.pod {

}
.main-box.pod a{
    cursor: pointer;
}
.pod-box1{
    width: 100%;
    height: 900px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background: var(--fundoQuaseBranco);
}
.pod-box1-a{
    
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 350px;
}
.p-box1-tit{
    font-family: Ebrima;
font-style: normal;
font-weight: bold;
font-size: 40px;
line-height: 150%;
display: flex;
align-items: center;
text-align: center;
color: var(--textoMaisEscuro);
}
.pod-box1 img {
    width: 354px;
}
.p-box1-texto{
    width: 740px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 175%;
    text-align: left;
}
.pod-box2{ width: 100%;
    height: 316px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background: var(--fundoBoxCreme);
}

.pod-box2-a {
    width: 500px ;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.pod-image{
    height: 60px;
    width: 60px;
}
.pod-box3{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background: var(--fundoQuaseBranco);
    padding: 25px 0;
}

@media only screen and (max-width: 768px){
    .pod-box1{
        height: auto;
        padding: 25px 0 25px 0;
    }
    .pod-box1-a{
        display: flex;
        flex-direction: column-reverse;
        height: 300px;
        width: 100%;
        padding: 0;
    }
    .p-box1-tit{
        font-family: Ebrima;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--textoMaisEscuro);
    }
    .pod-box1 img {
        width: 100%;
    }
    .p-box1-texto{
        margin-top: 15px;
        width: 90%;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 175%;
    }
}