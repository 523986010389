.main-mentoria {
    height: 565px;
    background: var(--fundoQuaseBranco);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    color: var(--textoEscuro)
}
.mentoria-image{
    height: 354px;
}
.mentoria-texto {
    width: 419px;
    height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items:center;
}
.p-box3-1 {
    font-weight: bold;
    font-size: 40px;
    line-height: 150%;
}
.p-mentoria-2 {
    font-weight: normal;
    text-align: justify;
    font-size: 20px;
    line-height: 150%;
}
@media only screen and (max-width: 768px) {
    .main-mentoria {
        padding: 25px 8px;
        height: fit-content;
    }
    /* .mentoria-image{
        width: 90%;

    } */

}