.main-python p {
    font-family: Ebrima;
    font-style: normal;
}
.python-titulo{
    height: 550px;
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background: var(--fundoBoxClaro)
}
.python-image{
    height: 300px;
}
.python-image2{
    height: 200px;
}
.titulo-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 500px;
}
.p-encerradas{
    font-family: Ebrima;
    font-style: normal;
    font-size: 32px;
    font-weight: 700;
    color: red;
    text-decoration: wavy;
}
.titulo-info p{
    width: 450px;
    font-weight: bold;
    font-size: 32px;
    line-height: 150%;
    text-align: left;
    color: var( --textoMaisEscuro);
}
.linha2-tit{
    
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items: center;
}
.linha2-tit p {
    margin-top: 8px;
    margin-left: 5px;
    
    font-weight: normal;
    font-size: 20px;
    line-height: 150%;
    text-align: left
}

.python-descr{
    background: var( --fundoBoxCreme);
    height: 510px;
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 0px;
}
.descr-texto{
    width: 700px;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    text-align: justify;
    /* color: var(--textoMaisEscuro) */
}


.python-porque{
    background: var( --fundoQuaseBranco);
    height: 750px;
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 0px;
}
.porque-tit{
    padding: none;
    margin: none;
    width: 650px;
    font-weight: bold;
    font-size: 28px;
    line-height: 150%;
    text-align: left;

    color: var(--textoMaisEscuro)
}

.python-aviso{
    height: 350px;
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background: var(--fundoBoxClaro)
}
.aviso-texto {
    width: 650px;
    font-weight: bold;
    font-size: 24px;
    line-height: 170%;
    text-align: justify;
    color: var(--textoMaisEscuro)
}

.python-info{
    background: var( --fundoBoxCreme);
    height: 530px;
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 0px;}

.python-conteudo{
    background: var( --fundoQuaseBranco);
    height: 450px;
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 0px;
}
.conteudo-texto{
    width: 300px;
    height: 50px;
    font-weight: normal;
    font-size: 20px;
    line-height: 130%;
    text-align: left;
    margin-bottom: 10px;
    /* padding-left: 1.5em;
    text-indent:-1.5em; */

}
.conteudo-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 900px;
}

.python-link{ height: 250px;
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background: var(--fundoBoxClaro)
}
.link-texto{
    width: 450px;
    font-weight: normal;
    font-size: 20px;
    line-height: 170%;
    text-align: justify;
    /* color: var(--textoMaisEscuro) */
}
.python-cancelamento{
    background: var( --fundoBoxCreme);
    height: 150px;
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 0px;
}
@media only screen and (max-width: 768px) {
    .python-titulo{
        display:flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background: var(--fundoBoxClaro);
        height: auto;
        padding-top: 25px;
    }
    .python-image{
        height: 200px;
        margin-bottom: 25px;
    }
    .titulo-info {
        height: auto;
        width: 90%;
    }
    .titulo-info a {
        margin-bottom: 25px;
    }
    .titulo-info p{
        font-size: 26px;
        width: 100%;
    }
    .linha2-tit p {
/*         margin-top: 8px;
        margin-left: 5px;
        font-weight: normal; */
        font-size: 20px;
        line-height: 140%;
        text-align: left
    }
    .linha2-tit{
        margin-bottom: 15px;
        
    }
    .python-descr{
        
        height: fit-content;
        display:flex;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        
    }
    .descr-info {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .descr-texto{
        width: 85%;
        font-size: 20px;
        margin-bottom: 25px;
        line-height: auto;
        /* color: var(--textoMaisEscuro) */
    }
    .python-porque{
        /* background: var( --fundoQuaseBranco); */
        height: fit-content;
        /* display:flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center; */
        padding: 25px 0px 25px 0px;
    }
    .porque-tit{
        padding: 0px 0px 5px 0px;
        /* margin: none; */
        width: 100%;
        /* font-weight: bold; */
        font-size: 28px;
        /* line-height: 150%; */
        text-align: center;
    
        color: var(--textoMaisEscuro)
    }
    
.python-aviso{
    height: 100%;
    display:flex;
    flex-direction: column;
    /* justify-content: space-evenly;
    align-items: center;
    background: var(--fundoBoxClaro); */
    padding: 25px 0px 25px 0px
}
.aviso-texto {
    width: 90%;
    font-size: 20px;
    margin-bottom: 15px;
}
.python-image2{
    height: 180px;
}
.python-info{
    height: fit-content;
    padding: 25px 0px 25px 0px;
}
.python-conteudo{
    height: fit-content;
    padding: 25px 0px 25px 0px;
}
.conteudo-texto{
    width: 100%;
    height: fit-content;
    margin-left:10px;
    /* padding-left: 1.5em;
    text-indent:-1.5em; */

}
.conteudo-flex {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    width: 90%;
    padding: 20px 0px;
}

.python-link{
    padding: 25px 0px 25px 0px;
    height: fit-content;
    display:flex;
    flex-direction: column;
}
.link-texto{
    width: 90%;
    margin-bottom: 25px;
}
.python-cancelamento{
    height: fit-content;
    padding: 25px 0px;
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 0px;
}
}