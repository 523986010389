.box2-d, .box2-m {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    color: var(--textoEscuro)
}
.p-box2-1{
    font-size: 24px;
    line-height: 30px;
    text-align: left;
    
}
@media only screen and (min-width: 768px){
    .box2 {
        height: 650px;
    } 
    .box2-m {
        display: none;
    }
    .box2-image{
        height: 413px;
    }
    .div-box2-direita {
        width: 451px;
        height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items:center;
    }
    .box2 p {
        width: 100%;
        text-align: left;
        font-size: 18px;
    }
    .p-box2-2{
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    
    }
    .p-box2-3{
        font-size: 24px;
        font-weight: 700;
        text-align: left;
    }
    
}
@media only screen and (max-width: 768px){
    .box2 {
        height: auto;
        background: var(--fundoBoxCreme);
    }
    .box2-d {
        display:none;
    }
    .box2-m {
        height: 1049px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .box2-image{
        width: 90%;
    }
    .box2 p {
        width: 90% ;
        text-align: left;
        font-size: 18px;
    }
    .p-box2-2{
    font-size: 18px;
    line-height: 27px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    }
}