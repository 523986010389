.box4 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    color: var(--textoEscuro)
}
.main-box p {
    font-family: Ebrima;
    font-style: normal;
}
.but-box4{
    background: var(--vermelhoLink);
    border: none;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 40px;
    color: #FAFAFF;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 31px;
}
@media only screen and (min-width: 768px){
    .box4 {
        height: 442px;
        background: var(--fundoBoxClaro);
    }
    .box4-image{
        height: 282px;
    }
    
    .div-box4-esquerda {
        width: 419px;
        height: 350px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items:center;
    }
    .p-box4-1 {
        font-weight: bold;
        font-size: 40px;
        line-height: 150%;
    }
    .p-box4-2 {
        font-weight: normal;
        text-align: justify;
        font-size: 18px;
        line-height: 175%;
    }
}
@media only screen and (max-width: 768px){
    .box4 {
        padding-top: 25px;
        height: auto;
        background: var(--fundoBoxClaro);
    }
    .box4-image{
        width: 90%;
    }
    .div-box4-esquerda {
        height: 350px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items:center;
    }
    .p-box4-1 {
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
    }
    .p-box4-2 {
        width: 85%;
        font-weight: normal;
        text-align: justify;
        font-size: 18px;
        line-height: 175%;
    }
    .but-box4{
        font-size: 18px;
        line-height: 24px;
    }
}