.div-parc-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    background: var(--fundoMaisBranco);
    height: 508px;
}
.p-titulo-parc {
    /* margin-top: 80px; */
    color: var(--textoMaisEscuro);
    font-family: Ebrima;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 150%;
}
.box-flex-parc{
    width: 950px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items:center;
}
.link-parc {
    width: 257px ;
    height: 248px;
    border-radius: 50%;
    margin-left: 10px;
}
@media only screen and (max-width: 768px){
    .box-flex-parc{
        width: 100%;
        justify-content: space-around;
    }
    .div-parc-main{
        height: fit-content;
        padding-bottom: 20px;
    }
    .link-parc {
        margin-top: 20px;
        margin-left: 0;
    }
}