.div-container-anchor-prin{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.div-anchor-container {
    display: flex;
    width: 480px;
    height: 173px;
    box-shadow: 0 8px 16px 0 rgba(110, 89, 89, 0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    background: var(--fundoBoxCreme);
    margin:20px;
    padding: 5px;
    

}
.div-anchor-esq{
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.linha1-anchor {
    width: 75px;
    height: 25px;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.linha1-anchor p{
    margin-top: 6px;
    
}
.div-anchor-container img{
    border: 1px solid white;
    border-radius: 50%;
    margin: 10px 0px;
    
}
@media (max-width: 960px) {
    .div-anchor-container {
        flex-direction: column;
        height: auto;}
    
}

.div-anchor-container texto {
    align-self: center;
    font-size: 22px;
}

.div-anchor-dir{
    width: 330px;
    height: auto;
    display:flex;
    margin-left: 8px;
    flex-direction: column;
    justify-content: space-between;
    color: var(--blue);
}
.p-anchor-titulo {
    font-size: 20px;
    height: auto;
    color: black;
    margin: initial;
    margin-top: 10px;
    margin-bottom: 8px;
    padding: initial;
    letter-spacing: 2px;;
}
.p-anchor-data {
    font-size: 13px;
    color: black;
    margin: initial;
    padding: initial;
    margin-bottom: 8px;
}
.span-anchor {
    align-self: flex-end;
}
.div-anchor-dir button{
    outline: none;
    border: none;
    background-color: inherit;
}
.div-anchor-dir button:hover{
    font-size: 18px;
    cursor: pointer;
}
.icons-wrapper-anchor {
    font-size: 30px;
    color: var(--laranja);
}
.a-btn {
    align-self: flex-end;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    padding-right: 10px;
    
}
.a-btn span {
    display:none;
    color: var(--azul);
    align-content: center;
    padding-right: 10px;
}
.a-btn:hover span {
    display: inline;
}