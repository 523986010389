.main-box p {
    font-family: Ebrima;
    font-style: normal;
}
.but-box {
    min-width: 150px;
    background: #701621;
    border: none;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 40px;
    color: #FAFAFF;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 31px;
    text-decoration: none !important
}

@media only screen and (min-width: 768px) {
    .but-box {
        min-width: 150px;
        font-size: 21px;
        line-height: 31px;
    }
}

@media only screen and (max-width: 768px) {
    .but-box {
        min-width: 100px;
        font-size: 16px;
        line-height: 24px;
    }
}