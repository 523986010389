.checkbox-main {
    background: none;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.checkbox-main:hover {
    cursor: pointer;
}
.checkbox-main p {
    font-weight: 400;
    font-size: 20px;
    line-height: 175%;
    font-style: normal;
    text-align: left;
    margin-left: 6px;
}

.checkbox-main img {
    /* border-radius: 5px; */
    
}