.boxTemp {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    color: var(--textoEscuro)
}
.template-main{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    min-height: 600px;
    background: var(--fundoBoxCreme);
}
.template-main p{
    font-family: Ebrima;
    font-style: normal;
}
.template-esq{
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    }
.template-dir{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 0px;
    height: 580px;
    /* border: 1px solid black; */
}
.p-temp1{
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--textoEscuro);
    margin-top: 50px

}
.p-tempTit{
    font-size: 36px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: var(--textoEscuro);
    margin: 25px 0px 50px 0px
}
/* .form-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin:auto;
    background-color: var(--cinza);
    border-radius: 5px;
} */
.form-main {
    /* height: 70%; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    padding: 5px 10px 5px 10px;
    border-radius: 3px;
    font-family: 'Poppins';
    font-style: normal;
    text-align: left;
}
.form-main .but-box {
    margin-top: 25px;
    align-self: center;
    
}
.form-main label{
    font-weight: 400;
    font-size: 18px;
    line-height: 175%;
    border: none
}
.form-main input{
    background: var(--fundoBoxClaro);
    padding: 16px 0px 16px 16px;
    border:none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 25px;
}
.p-messageArea{
    width: 100%;
    height: 33px;
    margin-top: 20px;
    padding: 5px 0px 0px 10px;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    border:none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    background: var(--fundoBoxClaro);

}
.p-messageAreaVazia{
    /* width: 90%; */
    height: 35px;
    margin-top: 20px;
}
.tituloForm{
    font-weight: 700;
    font-size: 32px;
    line-height: 150%;
    color: var( --textoMaisEscuro)
}
.bloco-radio-label span {
    margin-left: 10px;
    font-weight: 400;
    font-size: 20px;
    line-height: 175%;
}
@media only screen and (min-width: 768px) {
    .template-dir{
        width: 488px;
    }
    .boxTemp {
        height: 514px;
    }
    .boxTemp-image{
        height: 354px;
    }
    .div-boxTemp-esquerda {
        width: 419px;
        height: 450px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items:center;
    }
    .p-boxTemp-1 {
        font-weight: bold;
        font-size: 40px;
        line-height: 150%;
    }
    .p-boxTemp-2 {
        font-weight: normal;
        text-align: justify;
        font-size: 18px;
        line-height: 175%;  
    }
}
@media only screen and (max-width: 768px){
    .template-main{
        width: 100%;
        flex-direction: column-reverse;
        height: fit-content;
        padding: 20px 0px;
    }
    .template-esq{
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        }
    .template-dir{
        display: none;
        padding: 10px 0px;
        width: 400px;
        height: 500px;
        
    }
    .boxTemp {
        height: auto;
        padding-top: 25px;
        flex-wrap: wrap-reverse;
        
    }
    .boxTemp-image{
        width: 90%;
        height: auto;
    }
    .div-boxTemp-esquerda {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items:center;
        padding-bottom: 25px;
    }
    .p-boxTemp-1 {
        font-weight: bold;
        font-size: 28px;
        line-height: 150%;
    }
    .p-boxTemp-2 {
        width: 90%;
        font-weight: normal;
        text-align: justify;
        font-size: 18px;
        line-height: 175%;
    }
    .pod-box2-a{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}