/* Customize the label (the container) */
.container {
    display: block;
    position: relative;
    background-color: aqua;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid black;
  }
  
/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  /* Create a custom checkbox */
.checkmark {
    background-color: #eee;
  }
  