.box1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    color: var(--textoEscuro)
}
@media only screen and (min-width: 768px){
    .box1 {
        height: 520px;
        /* background: var(--fundoBoxClaro); */
    }
    .box1-image{
        height: 401px;
    }
    .box1-texto {
        width: 450px;
        font-weight: bold;
        font-size: 32px;
        line-height: 150%;
        text-align: left;
    }
}
@media only screen and (max-width: 768px){
    .box1 {
        height: auto;
        padding: 0 10px;
        /* background: var(--fundoBoxClaro); */
    }
    .box1-image{
        width: 100%;
    }
    .box1-texto {
        margin-top: 40px;
        width: 288px;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        text-align: left;
    }
}