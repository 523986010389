.vagas-sessao1 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    min-height: 480px;
    background-color: var(--fundoBoxCreme);
}

.imagem-vagas1 {
    height: 400px;
    border-radius: 5px;
}
.imagem-vagas1 {
    height: 400px;
    border-radius: 5px;
}

.sessa1-box-texto {
    width: 500px;
}
.sessa2-box-texto {
    width: 500px;
}

.sessao1-titulo {
    font-family: Ebrima;
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    line-height: 150%;
    text-align: center;
    color: var(--textoMaisEscuro);
    margin-bottom: 25px;
}

.sessao1-texto {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 175%;
    text-align: justify;
}

.vagas-sessao2 {
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    min-height: 400px;
    width: 100%;
}

.vaga-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    height: 330px;
    margin-bottom: 25px;
    background-color: var(--fundoBoxCreme);
    border-radius: 4px;
    box-shadow: 0 8px 16px 0 rgba(110, 89, 89, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.card-titulo {
    margin-top: 10px;
    padding-bottom: 8px;
    width: 90%;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 120%;
    text-align: center;
    border-bottom: 1px solid lightgrey;

}

.card-segunda-linha {
    align-self: flex-start;
    margin-top: 10px;
    padding-left: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
}

.card-texto1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 120%;
    text-align: left;


}

.card-terceira-linha {
    align-self: flex-start;
    margin-top: 15px;
    padding-left: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 45px;
    margin-bottom: 10px;

}

.card-texto2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 120%;
}

.botao-link {
    width: 140px;
    height: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    background: #701621;
    border: none;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    color: #FAFAFF;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    margin-bottom: 15px;
    }
.card-texto3{
    width: 85%;
    height: 70px;
    margin: 0px 0px 15px 25px;
    align-self: flex-start;
    text-align: justify;
}
.card-texto4{
    width: 85%;
    margin: 0px 0px 15px 25px;
    align-self: flex-start;
    text-align: justify;
}

@media only screen and (max-width: 768px) {
    .vagas-sessao1 {
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
        min-height: 900px;
    }

    .imagem-vagas1 {
        width: 90%;
        margin-bottom: 25px;
    }

    .sessa1-box-texto {
        width: 90%;
        padding: 15px 0px
    }

    .sessao1-titulo {
        font-size: 36px;
        line-height: 150%;
        margin-bottom: 25px;
    }

    .sessao1-texto {
        font-size: 18px;
    }

    .vagas-sessao2 {
        padding: 25px 0px;
        flex-direction: column;
        align-items: center;
    }

    .vaga-container {
        width: 90%;
    }

}